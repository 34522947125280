import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { SimpleCard } from 'components/Cards/SimpleCard';
import { useFlag, ActiveFlag } from '@automata/utils/hooks';

export interface GatedContentProps {
  unleashFlag: ActiveFlag;
  children: React.ReactNode;
}

export const GatedContent = ({
  unleashFlag,
  children,
}: GatedContentProps): JSX.Element | null => {
  const isEnabled = useFlag(unleashFlag);

  if (!isEnabled) {
    // TODO: Replace with Isaac's brand spanking new (and better looking) card
    // after PR
    return (
      <Container maxWidth="xs">
        <Box paddingTop={6}>
          <SimpleCard title="This page is not enabled">
            <Typography>Please contact Automata</Typography>
          </SimpleCard>
        </Box>
      </Container>
    );
  }

  return <>{children}</>;
};
